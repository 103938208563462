<template>
  <div class="fixed w-full h-screen top-0 left-0 right-0 z-50 bg-primary-500">
    <div class="max-w-md mx-auto py-3 px-5 flex items-center justify-between">
      <div class="w-3/12 flex items-center justify-start">
        <img :src="require('@/assets/images/logo-white.svg')" alt="Logo" class="h-8">
      </div>
      <div class="w-5/12 flex items-center justify-center">
      </div>
      <div class="w-3/12 flex items-center justify-end">
        <img @click="toggleSidebar" :src="require('@/assets/icons/sidebar-close.svg')" alt="Toggle" class="w-8 h-8 cursor-pointer">
      </div>
    </div>
    <div class="max-w-md mx-auto py-3 px-5">
      <div class="pb-2 w-full border-b border-red-500 mb-3 flex items-center justify-start text-white mb-8">
        <img :src="require('@/assets/icons/sidebar-profile.svg')" alt="Profile" class="w-8 h-8 mr-2">
        <span class="text-lg">{{ user.first_name }} {{ user.last_name }}</span>
      </div>
      <div class="mb-3">
        <router-link :to="{name: 'profile'}" active-class="cursor-default" class="text-2xl text-white capitalize">Your Profile</router-link>
      </div>
      <div class="mb-3">
        <router-link :to="{name: 'medias'}" active-class="cursor-default" class="text-2xl text-white capitalize">Your Uploaded Media</router-link>
      </div>
      <div class="mb-3">
        <router-link :to="{name: 'plans'}" active-class="cursor-default" class="text-2xl text-white capitalize">Billing Types</router-link>
      </div>
      <div class="mb-3">
        <router-link :to="{name: 'search'}" active-class="cursor-default" class="text-2xl text-white capitalize">Search Settings</router-link>
      </div>
      <div class="mb-3">
        <router-link :to="{name: 'search.result'}" active-class="cursor-default" class="text-2xl text-white capitalize">Search Results</router-link>
      </div>
      <div class="mb-3">
        <router-link :to="{name: 'reports'}" active-class="cursor-default" class="text-2xl text-white capitalize">Abuse Report</router-link>
      </div>
      <div class="mt-10">
        <a href="javascript:" @click="logout" class="text-2xl text-white capitalize">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "Sidebar",
    computed: {
      ...mapState({
        user: state => state.auth.user
      })
    },
    methods: {
      toggleSidebar() {
        this.$store.dispatch('sidebar/toggle');
      },
      logout() {
        this.$store.dispatch('auth/logout');
      }
    }
  }
</script>

<style scoped>

</style>
