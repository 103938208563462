<template>
  <div class="inline">
    <a href="javascript:" class="underline" @click="$refs.submitModal.show()">Report Abuse</a>
    <v-modal ref="submitModal">
      <div class="bg-white p-5 rounded-xl">
        <h2 class="text-xl mb-2">Report an Abuse</h2>
        <div class="flex items-center justify-end">
          <v-button type="secondary" class="mr-2" @click="$refs.submitModal.hide()">
            Cancel
          </v-button>
          <v-button @click="submit" :disabled="submitting">Submit</v-button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script>
  import VModal from "@/components/Modal";
  import VButton from "@/components/Button";
  import {mapState} from "vuex";

  export default {
    name: "ReportAbuse",
    components: {VButton, VModal},
    computed: {
      ...mapState({
        submitted: state => state.report.submitted
      })
    },
    data() {
      return {
        submitting: false,
        form: {
          website: '',
          url: ''
        }
      };
    },
    methods: {
      async submit() {
        this.submitting = true;
        await this.$store.dispatch('report/submit', this.form);
        this.submitting = false;
        if (this.submitted) {
          this.$refs.submitModal.hide();
        }
      }
    }
  }
</script>
