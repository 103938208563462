<template>
  <div class="w-full rounded-xl bg-white border border-gray-200 py-4 px-5">
    <div class="flex items-center justify-between">
      <div class="w-full">
        <div class="mb-1">
          <strong>Report date: </strong>
          <span>{{ report.created_at | datetime }}</span>
        </div>
        <div class="mb-3">
          <a :href="report.url" target="_blank" class="text-primary-500 underline">
            View
          </a>
        </div>
      </div>
      <div class="w-2/12 flex items-center justify-center">
        <router-link :to="{name: 'reports.show', params: {id: report.id}}">
          <img :src="require('@/assets/icons/arrow-right.svg')" alt="View" class="w-5 h-50">
        </router-link>
      </div>
    </div>
    <div class="mt-2">
      <div class="text-sm mb-2">REPORT STATUS:</div>
      <report-status />
    </div>
  </div>
</template>

<script>
  import ReportStatus from "@/views/reports/ReportStatus";

  export default {
    name: "ReportItem",
    components: {ReportStatus},
    props: {
      report: Object
    },
  }
</script>
