<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Report Details" :back="true" />
      <div class="w-full h-full max-w-md mx-auto py-10 px-5">
        <div class="w-full rounded-xl border border-gray-200 bg-white p-5">
          <div class="flex items-start justify-start py-2 px-5 w-full">
            <div class="w-3/12 flex items-center justify-start mr-3">
              <img :src="require('@/assets/images/sample-person.jpeg')" alt="Image" class="w-16 h-16 rounded-full shadow-2xl">
            </div>
            <div class="w-9/12">
              <div class="mb-1">
                <strong>Search date: </strong>
                <span>25-08-12</span>
              </div>
              <div class="mb-1">
                <strong>Report date: </strong>
                <span>25-08-12</span>
              </div>
              <div class="mb-3">
                <a href="#" target="_blank" class="text-primary-500 underline">
                  View this photo on Twitter
                </a>
              </div>
            </div>
          </div>
          <div class="px-2 mb-3">Report Status:</div>
          <div class=" py-4 px-5">
            <div class="w-full border-l-2 border-dashed border-gray-300">
              <div class="flex items-center justify-start mb-5">
                <img :src="require('@/assets/icons/circle-checked.svg')" alt="Checked" class="status w-8 h-8 mr-2">
                <span class="text-green-500">Report abuse</span>
              </div>
              <div class="flex items-center justify-start mb-5">
                <img :src="require('@/assets/icons/circle-arrow-right.svg')" alt="Active" class="status w-8 h-8 mr-2">
                <span class="text-primary-500">Abuse reported to medium</span>
              </div>
              <div class="flex items-center justify-start mb-5">
                <img :src="require('@/assets/icons/circle-empty.svg')" alt="Disable" class="status bg-gray-200 rounded-full w-8 h-8 mr-2">
                <span class="text-gray-500">Pending medium response</span>
              </div>
              <div class="flex items-center justify-start mb-5">
                <img :src="require('@/assets/icons/circle-empty.svg')" alt="Disable" class="status bg-gray-200 rounded-full w-8 h-8 mr-2">
                <span class="text-gray-500">Medium response received</span>
              </div>
              <div class="flex items-center justify-start">
                <img :src="require('@/assets/icons/circle-empty.svg')" alt="Disable" class="status bg-gray-200 rounded-full w-8 h-8 mr-2">
                <span class="text-gray-500">Case closed</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VHeader from "@/components/Header";

  export default {
    name: "ShowReport",
    components: {VHeader, AppLayout}
  }
</script>

<style scoped>
  .status {
    margin-left: -1.05rem;
  }
</style>
