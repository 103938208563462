<template>
  <button v-if="type === 'primary'" @click="$emit('click')" class="inline-flex shadow-lg items-center justify-center px-4 py-2 bg-primary-500 border border-transparent rounded-xl capitalize text-white active:bg-primary-600 focus:outline-none focus:border-primary-400 focus:ring focus:ring-primary-400 transition" :class="{'hover:bg-primary-600': !disabled, 'opacity-25': disabled}" :disabled="disabled">
    <slot></slot>
  </button>
  <button v-else-if="type === 'secondary'" @click="$emit('click')" class="inline-flex shadow-sm items-center justify-center px-4 py-2 bg-white border border-gray-900 border-transparent rounded-xl capitalize text-gray-800 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-400 transition" :class="{'hover:bg-gray-900 hover:text-white': !disabled, 'opacity-25': disabled}" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'VButton',
    props: {
      type: {
        type: String,
        default: 'primary',
      },
      disabled: Boolean
    }
  }
</script>
