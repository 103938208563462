const state = {
  ready: false,
  home_route: 'search.result',
  errors: {}
};

const getters = {
  ready: state => {
    return state.ready;
  },
  errors: state => {
    return state.errors;
  }
};

const actions = {
  validationErrors({commit}, errors) {
    let errs = {};
    errors.map(err => {
      let key = err.replace(/ .*/, '');
      errs[key] = err;
    });
    commit('SET_ERRORS', errs);
  },
  emptyErrors({commit}) {
    commit('SET_ERRORS', {});
  }
};

const mutations = {
  SET_READY(state, status) {
    state.ready = status;
  },
  SET_ERRORS(state, errors) {
    state.errors = errors;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
