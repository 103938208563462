<template>
  <div>
    <div class="flex items-center justify-between w-full border border-gray-200 rounded-xl mb-5">
      <div class="flex-none bg-cover bg-no-repeat bg-center rounded-xl h-16 w-16 md:h-18 md:w-32 bg-gray-200" :style="`background-image: url(https://picsum.photos/536/${result.id * 100})`"></div>
      <div class="flex-grow flex flex-col justify-center ml-3 h-16 md:h-18 py-2">
        <div class="text-gray-400 text-xs mb-1"><b class="text-gray-600">Search date: </b>{{ result.created_at | dateformat }}</div>
        <div class="flex items-center">
          <img :src="require(`@/assets/websites/${result.website}.svg`)" alt="View" class="w-5 h-5 mr-2">
          <div v-if="result.is_reported" class="bg-green-100 px-2 py-1 rounded-md text-green-900 text-center inline-flex items-center">
            <img :src="require('@/assets/icons/checked.svg')" alt="Checked" class="w-3 h-3 mr-1">
            <span class="text-xs">Reported</span>
          </div>
        </div>
      </div>
      <div class="flex-none flex items-center justify-center mr-4">
        <a v-if="result.is_reported" href="javascript:" @click="showReportDetail">
          <img :src="require('@/assets/icons/arrow-right.svg')" alt="View" class="w-5 h-5 mx-auto">
        </a>
        <a v-else href="javascript:" @click="showDismiss">
          <img :src="require('@/assets/icons/close.svg')" alt="Close" class="w-5 h-5 mx-auto">
        </a>
      </div>
    </div>
    <v-modal ref="reportModal">
      <div class="bg-white p-5 rounded-xl">
        <h2 class="text-xl mb-2">Reporting Abuse</h2>
        <p class="mb-5">By reporting abuse... [legal copy goes here]</p>
        <div class="flex items-center justify-end">
          <v-button type="secondary" class="mr-2" @click="$refs.reportModal.hide()">
            Cancel
          </v-button>
          <v-button>Report</v-button>
        </div>
      </div>
    </v-modal>
    <v-modal ref="dismissModal">
      <div class="bg-white p-5 rounded-xl">
        <h2 class="text-xl mb-2">Are you sure you want to dismiss this case?</h2>
        <p class="mb-5">By reporting abuse... [legal copy goes here]</p>
        <div class="flex items-center justify-end">
          <v-button type="secondary" class="mr-2" @click="$refs.dismissModal.hide()">
            Cancel
          </v-button>
          <v-button>Dismiss</v-button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script>
  import VModal from "@/components/Modal";
  import VButton from "@/components/Button";

  export default {
    name: "ResultItem",
    components: {VButton, VModal},
    props: {
      result: Object
    },
    data() {
      return {}
    },
    methods: {
      showReport() {
        this.$refs.reportModal.show();
      },
      showDismiss() {
        this.$refs.dismissModal.show();
      },
      showReportDetail() {
        this.$router.push({
          name: 'reports.show',
          params: {
            id: '1'
          }
        })
      }
    }
  }
</script>
