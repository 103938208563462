import Cookie from 'js-cookie';
import router from '@/router';

const state = {
  authenticated: false,
  access_token: null,
  user: {},
  errors: {
    login: {},
    register: {}
  }
};

const getters = {
  authenticated: state => {
    return state.authenticated;
  },
  access_token: state => {
    return state.access_token;
  },
  user: state => {
    return state.user;
  },
  errors: state => {
    return state.errors;
  }
};

const actions = {
  async google({rootState}) {
    window.location.href = rootState.config.api_url
      + '/auth/google?from='
      + window.location.href
      + '/google';
  },
  async googleCallback({dispatch}, access_token) {
    await Cookie.set('access_token', access_token);
    await dispatch('fetchUser');
  },
  async fetchUser({commit}) {
    let access_token = Cookie.get('access_token');
    commit('SET_ACCESS_TOKEN', access_token);
    if (access_token) {
      window.axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      await window.axios.get('/user/me').then(response => {
        commit('SET_USER', response.data);
        commit('SET_AUTHENTICATED', true);
      }).catch(() => {
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
      });
    } else {
      commit('SET_AUTHENTICATED', false);
      commit('SET_USER', null);
    }
  },
  async completeInfo({commit, dispatch}, form) {
    dispatch('app/emptyErrors', null, {root: true});
    await window.axios.post('/auth/info', form).then((response) => {
      commit('SET_USER', response.data);
      router.push({name: 'plans'});
    }).catch(() => {
    });
  },
  async redirect({state}) {
    switch (state.user.registration_step) {
      case 'info':
        await router.replace({name: 'auth.info'});
        break;
      case 'plan':
        await router.replace({name: 'plans'});
        break;
      case 'video':
        await router.replace({name: 'upload_media'});
        break;
      case 'search_setup':
        await router.replace({name: 'search'});
        break;
    }
  },
  logout({commit}) {
    Cookie.remove('access_token');
    commit('SET_AUTHENTICATED', false);
    commit('SET_USER', null);
    window.location.href = '/';
  }
};

const mutations = {
  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_ACCESS_TOKEN(state, access_token) {
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    state.access_token = access_token;
  },
  SET_USER(state, user) {
    state.user = user;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
