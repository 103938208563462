const state = {
  reports: [],
  submitted: false
};

const getters = {};

const actions = {
  async getReports({commit}) {
    await window.axios.get('/abuse-reports').then((response) => {
      commit('SET_REPORTS', response.data);
    }).catch(() => {
    });
  },
  async submit({commit, dispatch}, form) {
    commit('SET_SUBMITTED', false);
    dispatch('app/emptyErrors', null, {root: true});
    await window.axios.post('/abuse-reports', form).then(() => {
      dispatch('getReports');
      commit('SET_SUBMITTED', true);
    }).catch(() => {
      commit('SET_SUBMITTED', false);
    });
  }
};

const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  SET_SUBMITTED(state, submitted) {
    state.submitted = submitted;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
