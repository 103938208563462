<template>
  <div class="inline-flex">
    <input ref="checkbox" type="checkbox" :checked="isChecked || checked || forceChecked" :value="value" @change="updateInput" class="form-checkbox w-8 h-8 rounded text-primary-500" :class="{'ring ring-red-500': hasError}" :disabled="disabled">
  </div>
</template>

<script>
  export default {
    name: 'VCheckbox',
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      "value": {type: String},
      "modelValue": {default: ""},
      "trueValue": {default: true},
      "falseValue": {default: false},
      "error": {type: String},
      "disabled": Boolean,
      "checked": Boolean
    },
    data() {
      return {
        hasError: !!this.error,
        forceChecked: false
      }
    },
    watch: {
      error(newVal) {
        this.hasError = !!newVal
      }
    },
    computed: {
      isChecked() {
        if (this.modelValue instanceof Array) {
          return this.modelValue.includes(this.value)
        }
        // Note that `true-value` and `false-value` are camelCase in the JS
        return this.modelValue === this.trueValue
      }
    },
    methods: {
      triggerClick() {
        this.$refs.checkbox.click();
      },
      updateInput(event) {
        let isChecked = event.target.checked
        if (isChecked) {
          this.hasError = false;
        }
        if (this.modelValue instanceof Array) {
          let newValue = [...this.modelValue]
          if (isChecked) {
            newValue.push(this.value)
          } else {
            newValue.splice(newValue.indexOf(this.value), 1)
          }
          this.$emit('change', newValue)
        } else {
          this.$emit('change', isChecked ? this.trueValue : this.falseValue)
        }
      }
    }

  }
</script>
