<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Your Media" />
      <div class="w-full h-full max-w-md mx-auto py-5 px-5">
        <v-loader v-if="loading" class="h-3" />
        <div v-else>
          <div class="mb-3 flex items-center justify-between">
            <div>Your uploaded media is here</div>
            <v-button @click="showUpload">Upload</v-button>
          </div>
          <div v-for="media in medias" :key="media.id" class="flex items-center justify-between py-4 w-full border-b border-gray-200">
            <div class="flex-none bg-cover bg-center rounded-lg h-12 w-12 shadow-lg" :style="`background-image: url(${media.thumbnail_url})`"></div>
            <div class="flex-grow ml-3">
              <div class="text-gray-600 mb-1">{{ media.type }}</div>
              <div class="text-gray-400 text-xs">{{ media.created_at | dateformat }}</div>
            </div>
            <div class="flex-none">
              <a :href="media.url" class="text-primary-500 underline" target="_blank">View</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
  import {mapState} from "vuex";
  import AppLayout from "@/layouts/AppLayout";
  import VHeader from "@/components/Header";
  import VLoader from "@/components/Loader";
  import VButton from "@/components/Button";

  export default {
    name: "Profile",
    components: {
      VButton,
      VLoader,
      VHeader,
      AppLayout
    },
    computed: {
      ...mapState({
        user: state => state.auth.user,
        medias: state => state.media.medias
      })
    },
    data() {
      return {
        loading: true
      }
    },
    mounted() {
      this.getMedias();
    },
    methods: {
      async getMedias() {
        await this.$store.dispatch('media/get');
        this.loading = false;
      },
      showUpload() {
        this.$router.push({name: 'upload_media'})
      }
    }
  }
</script>
