<template>
  <app-layout>
    <v-header :title="`Hi ${user.first_name}`" :sidebar="user.registration_completed" />
    <div class="w-full max-w-md mx-auto p-5">
      <div class="text-center mb-5 text-gray-600 px-5">
        Please choose a plan to continue
      </div>
      <div @click="selectPlan('free')" class="border border-ice rounded-2xl p-5 w-full hover:bg-ice-500 hover:border-ice-500 hover:shadow-xl cursor-pointer mb-5">
        <h2 class="text-2xl font-bold text-primary text-center mb-3">Free</h2>
        <p class="text-center text-sm">
          You can choose one of the platforms to scan for unwanted content. You will receive the
          results in a protected form.
        </p>
      </div>
      <div class="border border-ice rounded-2xl p-5 w-full mb-5 filter grayscale">
        <h2 class="text-2xl font-bold text-primary text-center mb-3">10 €
          <span class="font-light">/month</span>
        </h2>
        <p class="text-center text-sm">
          You can use all available platforms to scan for unwanted content. You will receive the
          results in a protected form, where you can decide to take action.
        </p>
      </div>
      <div class="border border-ice rounded-2xl p-5 w-full mb-5 filter grayscale">
        <h2 class="text-2xl font-bold text-primary text-center mb-3">Credits</h2>
        <p class="text-center text-sm">
          You can use all available platforms to scan for unwanted content. You will receive the
          results in a protected form, where you can decide to take action.
        </p>
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VHeader from "@/components/Header";
  import {mapState} from "vuex";

  export default {
    name: "Plans",
    components: {
      VHeader,
      AppLayout
    },
    computed: {
      ...mapState({
        user: state => state.auth.user
      })
    },
    methods: {
      async selectPlan(type) {
        await this.$store.dispatch('subscription/selectPlan', type);
        await this.$router.push({name: 'upload_media'})
      }
    }
  }
</script>
