<template>
  <div class="w-full grid grid-cols-5 gap-0.5">
    <div class="w-full bg-green-500 h-3 rounded-tl-lg rounded-bl-lg"></div>
    <div class="w-full bg-green-500 h-3"></div>
    <div class="w-full bg-primary h-3 relative">
      <img :src="require('@/assets/icons/active-report-status.svg')" alt="Active" class="w-3 h-3 mx-auto mt-3">
    </div>
    <div class="w-full bg-gray-200 h-3"></div>
    <div class="w-full bg-gray-200 h-3 rounded-tr-lg rounded-br-lg"></div>
  </div>
</template>

<script>
  export default {
    name: "ReportStatus",
    props: {
      report: Object,
      default() {
        return {
          status: '1'
        }
      }
    }
  }
</script>
