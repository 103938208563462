<template>
  <div id="app">
    <router-view v-if="ready"></router-view>
    <div v-else class="w-full h-screen flex items-center justify-center text-xl text-primary-500">
      Please wait...
    </div>
    <v-toast ref="toast" />
    <cookie />
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Cookie from "@/components/Cookie";
  import VToast from "@/components/Toast";

  export default {
    name: "App",
    components: {VToast, Cookie},
    computed: {
      ...mapState({
        ready: state => state.app.ready
      })
    }
  };
</script>
