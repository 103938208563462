export default [
  {"value": "af", "img": "/assets/flags/af.svg", "title": "Afghanistan"},
  {"value": "al", "img": "/assets/flags/al.svg", "title": "Albania"},
  {"value": "dz", "img": "/assets/flags/dz.svg", "title": "Algeria"},
  {"value": "as", "img": "/assets/flags/as.svg", "title": "American Samoa"},
  {"value": "ad", "img": "/assets/flags/ad.svg", "title": "Andorra"},
  {"value": "ao", "img": "/assets/flags/ao.svg", "title": "Angola"},
  {"value": "ai", "img": "/assets/flags/ai.svg", "title": "Anguilla"},
  {"value": "aq", "img": "/assets/flags/aq.svg", "title": "Antarctica"},
  {"value": "ag", "img": "/assets/flags/ag.svg", "title": "Antigua and Barbuda"},
  {"value": "ar", "img": "/assets/flags/ar.svg", "title": "Argentina"},
  {"value": "am", "img": "/assets/flags/am.svg", "title": "Armenia"},
  {"value": "aw", "img": "/assets/flags/aw.svg", "title": "Aruba"},
  {"value": "au", "img": "/assets/flags/au.svg", "title": "Australia"},
  {"value": "at", "img": "/assets/flags/at.svg", "title": "Austria"},
  {"value": "az", "img": "/assets/flags/az.svg", "title": "Azerbaijan"},
  {"value": "bs", "img": "/assets/flags/bs.svg", "title": "Bahamas"},
  {"value": "bh", "img": "/assets/flags/bh.svg", "title": "Bahrain"},
  {"value": "bd", "img": "/assets/flags/bd.svg", "title": "Bangladesh"},
  {"value": "bb", "img": "/assets/flags/bb.svg", "title": "Barbados"},
  {"value": "by", "img": "/assets/flags/by.svg", "title": "Belarus"},
  {"value": "be", "img": "/assets/flags/be.svg", "title": "Belgium"},
  {"value": "bz", "img": "/assets/flags/bz.svg", "title": "Belize"},
  {"value": "bj", "img": "/assets/flags/bj.svg", "title": "Benin"},
  {"value": "bm", "img": "/assets/flags/bm.svg", "title": "Bermuda"},
  {"value": "bt", "img": "/assets/flags/bt.svg", "title": "Bhutan"},
  {"value": "bo", "img": "/assets/flags/bo.svg", "title": "Bolivia"},
  {"value": "bq", "img": "/assets/flags/bq.svg", "title": "Bonaire, Sint Eustatius and Saba"},
  {"value": "ba", "img": "/assets/flags/ba.svg", "title": "Bosnia and Herzegovina"},
  {"value": "bw", "img": "/assets/flags/bw.svg", "title": "Botswana"},
  {"value": "bv", "img": "/assets/flags/bv.svg", "title": "Bouvet Island"},
  {"value": "br", "img": "/assets/flags/br.svg", "title": "Brazil"},
  {"value": "io", "img": "/assets/flags/io.svg", "title": "British Indian Ocean Territory"},
  {"value": "bn", "img": "/assets/flags/bn.svg", "title": "Brunei Darussalam"},
  {"value": "bg", "img": "/assets/flags/bg.svg", "title": "Bulgaria"},
  {"value": "bf", "img": "/assets/flags/bf.svg", "title": "Burkina Faso"},
  {"value": "bi", "img": "/assets/flags/bi.svg", "title": "Burundi"},
  {"value": "cv", "img": "/assets/flags/cv.svg", "title": "Cabo Verde"},
  {"value": "kh", "img": "/assets/flags/kh.svg", "title": "Cambodia"},
  {"value": "cm", "img": "/assets/flags/cm.svg", "title": "Cameroon"},
  {"value": "ca", "img": "/assets/flags/ca.svg", "title": "Canada"},
  {"value": "ky", "img": "/assets/flags/ky.svg", "title": "Cayman Islands"},
  {"value": "cf", "img": "/assets/flags/cf.svg", "title": "Central African Republic"},
  {"value": "td", "img": "/assets/flags/td.svg", "title": "Chad"},
  {"value": "cl", "img": "/assets/flags/cl.svg", "title": "Chile"},
  {"value": "cn", "img": "/assets/flags/cn.svg", "title": "China"},
  {"value": "cx", "img": "/assets/flags/cx.svg", "title": "Christmas Island"},
  {"value": "cc", "img": "/assets/flags/cc.svg", "title": "Cocos (Keeling) Islands"},
  {"value": "co", "img": "/assets/flags/co.svg", "title": "Colombia"},
  {"value": "km", "img": "/assets/flags/km.svg", "title": "Comoros"},
  {"value": "cd", "img": "/assets/flags/cd.svg", "title": "Congo (the Democratic Republic of the)"},
  {"value": "cg", "img": "/assets/flags/cg.svg", "title": "Congo"},
  {"value": "ck", "img": "/assets/flags/ck.svg", "title": "Cook Islands"},
  {"value": "cr", "img": "/assets/flags/cr.svg", "title": "Costa Rica"},
  {"value": "hr", "img": "/assets/flags/hr.svg", "title": "Croatia"},
  {"value": "cu", "img": "/assets/flags/cu.svg", "title": "Cuba"},
  {"value": "cw", "img": "/assets/flags/cw.svg", "title": "Curaçao"},
  {"value": "cy", "img": "/assets/flags/cy.svg", "title": "Cyprus"},
  {"value": "cz", "img": "/assets/flags/cz.svg", "title": "Czechia"},
  {"value": "ci", "img": "/assets/flags/ci.svg", "title": "Côte d'Ivoire"},
  {"value": "dk", "img": "/assets/flags/dk.svg", "title": "Denmark"},
  {"value": "dj", "img": "/assets/flags/dj.svg", "title": "Djibouti"},
  {"value": "dm", "img": "/assets/flags/dm.svg", "title": "Dominica"},
  {"value": "do", "img": "/assets/flags/do.svg", "title": "Dominican Republic"},
  {"value": "ec", "img": "/assets/flags/ec.svg", "title": "Ecuador"},
  {"value": "eg", "img": "/assets/flags/eg.svg", "title": "Egypt"},
  {"value": "sv", "img": "/assets/flags/sv.svg", "title": "El Salvador"},
  {"value": "gq", "img": "/assets/flags/gq.svg", "title": "Equatorial Guinea"},
  {"value": "er", "img": "/assets/flags/er.svg", "title": "Eritrea"},
  {"value": "ee", "img": "/assets/flags/ee.svg", "title": "Estonia"},
  {"value": "sz", "img": "/assets/flags/sz.svg", "title": "Eswatini"},
  {"value": "et", "img": "/assets/flags/et.svg", "title": "Ethiopia"},
  {"value": "fk", "img": "/assets/flags/fk.svg", "title": "Falkland Islands [Malvinas]"},
  {"value": "fo", "img": "/assets/flags/fo.svg", "title": "Faroe Islands"},
  {"value": "fj", "img": "/assets/flags/fj.svg", "title": "Fiji"},
  {"value": "fi", "img": "/assets/flags/fi.svg", "title": "Finland"},
  {"value": "fr", "img": "/assets/flags/fr.svg", "title": "France"},
  {"value": "gf", "img": "/assets/flags/gf.svg", "title": "French Guiana"},
  {"value": "pf", "img": "/assets/flags/pf.svg", "title": "French Polynesia"},
  {"value": "tf", "img": "/assets/flags/tf.svg", "title": "French Southern Territories"},
  {"value": "ga", "img": "/assets/flags/ga.svg", "title": "Gabon"},
  {"value": "gm", "img": "/assets/flags/gm.svg", "title": "Gambia"},
  {"value": "ge", "img": "/assets/flags/ge.svg", "title": "Georgia"},
  {"value": "de", "img": "/assets/flags/de.svg", "title": "Germany"},
  {"value": "gh", "img": "/assets/flags/gh.svg", "title": "Ghana"},
  {"value": "gi", "img": "/assets/flags/gi.svg", "title": "Gibraltar"},
  {"value": "gr", "img": "/assets/flags/gr.svg", "title": "Greece"},
  {"value": "gl", "img": "/assets/flags/gl.svg", "title": "Greenland"},
  {"value": "gd", "img": "/assets/flags/gd.svg", "title": "Grenada"},
  {"value": "gp", "img": "/assets/flags/gp.svg", "title": "Guadeloupe"},
  {"value": "gu", "img": "/assets/flags/gu.svg", "title": "Guam"},
  {"value": "gt", "img": "/assets/flags/gt.svg", "title": "Guatemala"},
  {"value": "gg", "img": "/assets/flags/gg.svg", "title": "Guernsey"},
  {"value": "gn", "img": "/assets/flags/gn.svg", "title": "Guinea"},
  {"value": "gw", "img": "/assets/flags/gw.svg", "title": "Guinea-Bissau"},
  {"value": "gy", "img": "/assets/flags/gy.svg", "title": "Guyana"},
  {"value": "ht", "img": "/assets/flags/ht.svg", "title": "Haiti"},
  {"value": "hm", "img": "/assets/flags/hm.svg", "title": "Heard Island and McDonald Islands"},
  {"value": "va", "img": "/assets/flags/va.svg", "title": "Holy See"},
  {"value": "hn", "img": "/assets/flags/hn.svg", "title": "Honduras"},
  {"value": "hk", "img": "/assets/flags/hk.svg", "title": "Hong Kong"},
  {"value": "hu", "img": "/assets/flags/hu.svg", "title": "Hungary"},
  {"value": "is", "img": "/assets/flags/is.svg", "title": "Iceland"},
  {"value": "in", "img": "/assets/flags/in.svg", "title": "India"},
  {"value": "id", "img": "/assets/flags/id.svg", "title": "Indonesia"},
  {"value": "ir", "img": "/assets/flags/ir.svg", "title": "Iran (Islamic Republic of)"},
  {"value": "iq", "img": "/assets/flags/iq.svg", "title": "Iraq"},
  {"value": "ie", "img": "/assets/flags/ie.svg", "title": "Ireland"},
  {"value": "im", "img": "/assets/flags/im.svg", "title": "Isle of Man"},
  {"value": "il", "img": "/assets/flags/il.svg", "title": "Israel"},
  {"value": "it", "img": "/assets/flags/it.svg", "title": "Italy"},
  {"value": "jm", "img": "/assets/flags/jm.svg", "title": "Jamaica"},
  {"value": "jp", "img": "/assets/flags/jp.svg", "title": "Japan"},
  {"value": "je", "img": "/assets/flags/je.svg", "title": "Jersey"},
  {"value": "jo", "img": "/assets/flags/jo.svg", "title": "Jordan"},
  {"value": "kz", "img": "/assets/flags/kz.svg", "title": "Kazakhstan"},
  {"value": "ke", "img": "/assets/flags/ke.svg", "title": "Kenya"},
  {"value": "ki", "img": "/assets/flags/ki.svg", "title": "Kiribati"},
  {"value": "kp", "img": "/assets/flags/kp.svg", "title": "Korea (the Democratic People's Republic of)"},
  {"value": "kr", "img": "/assets/flags/kr.svg", "title": "Korea (the Republic of)"},
  {"value": "kw", "img": "/assets/flags/kw.svg", "title": "Kuwait"},
  {"value": "kg", "img": "/assets/flags/kg.svg", "title": "Kyrgyzstan"},
  {"value": "la", "img": "/assets/flags/la.svg", "title": "Lao People's Democratic Republic"},
  {"value": "lv", "img": "/assets/flags/lv.svg", "title": "Latvia"},
  {"value": "lb", "img": "/assets/flags/lb.svg", "title": "Lebanon"},
  {"value": "ls", "img": "/assets/flags/ls.svg", "title": "Lesotho"},
  {"value": "lr", "img": "/assets/flags/lr.svg", "title": "Liberia"},
  {"value": "ly", "img": "/assets/flags/ly.svg", "title": "Libya"},
  {"value": "li", "img": "/assets/flags/li.svg", "title": "Liechtenstein"},
  {"value": "lt", "img": "/assets/flags/lt.svg", "title": "Lithuania"},
  {"value": "lu", "img": "/assets/flags/lu.svg", "title": "Luxembourg"},
  {"value": "mo", "img": "/assets/flags/mo.svg", "title": "Macao"},
  {"value": "mg", "img": "/assets/flags/mg.svg", "title": "Madagascar"},
  {"value": "mw", "img": "/assets/flags/mw.svg", "title": "Malawi"},
  {"value": "my", "img": "/assets/flags/my.svg", "title": "Malaysia"},
  {"value": "mv", "img": "/assets/flags/mv.svg", "title": "Maldives"},
  {"value": "ml", "img": "/assets/flags/ml.svg", "title": "Mali"},
  {"value": "mt", "img": "/assets/flags/mt.svg", "title": "Malta"},
  {"value": "mh", "img": "/assets/flags/mh.svg", "title": "Marshall Islands"},
  {"value": "mq", "img": "/assets/flags/mq.svg", "title": "Martinique"},
  {"value": "mr", "img": "/assets/flags/mr.svg", "title": "Mauritania"},
  {"value": "mu", "img": "/assets/flags/mu.svg", "title": "Mauritius"},
  {"value": "yt", "img": "/assets/flags/yt.svg", "title": "Mayotte"},
  {"value": "mx", "img": "/assets/flags/mx.svg", "title": "Mexico"},
  {"value": "fm", "img": "/assets/flags/fm.svg", "title": "Micronesia (Federated States of)"},
  {"value": "md", "img": "/assets/flags/md.svg", "title": "Moldova (the Republic of)"},
  {"value": "mc", "img": "/assets/flags/mc.svg", "title": "Monaco"},
  {"value": "mn", "img": "/assets/flags/mn.svg", "title": "Mongolia"},
  {"value": "me", "img": "/assets/flags/me.svg", "title": "Montenegro"},
  {"value": "ms", "img": "/assets/flags/ms.svg", "title": "Montserrat"},
  {"value": "ma", "img": "/assets/flags/ma.svg", "title": "Morocco"},
  {"value": "mz", "img": "/assets/flags/mz.svg", "title": "Mozambique"},
  {"value": "mm", "img": "/assets/flags/mm.svg", "title": "Myanmar"},
  {"value": "na", "img": "/assets/flags/na.svg", "title": "Namibia"},
  {"value": "nr", "img": "/assets/flags/nr.svg", "title": "Nauru"},
  {"value": "np", "img": "/assets/flags/np.svg", "title": "Nepal"},
  {"value": "nl", "img": "/assets/flags/nl.svg", "title": "Netherlands"},
  {"value": "nc", "img": "/assets/flags/nc.svg", "title": "New Caledonia"},
  {"value": "nz", "img": "/assets/flags/nz.svg", "title": "New Zealand"},
  {"value": "ni", "img": "/assets/flags/ni.svg", "title": "Nicaragua"},
  {"value": "ne", "img": "/assets/flags/ne.svg", "title": "Niger"},
  {"value": "ng", "img": "/assets/flags/ng.svg", "title": "Nigeria"},
  {"value": "nu", "img": "/assets/flags/nu.svg", "title": "Niue"},
  {"value": "nf", "img": "/assets/flags/nf.svg", "title": "Norfolk Island"},
  {"value": "mp", "img": "/assets/flags/mp.svg", "title": "Northern Mariana Islands"},
  {"value": "no", "img": "/assets/flags/no.svg", "title": "Norway"},
  {"value": "om", "img": "/assets/flags/om.svg", "title": "Oman"},
  {"value": "pk", "img": "/assets/flags/pk.svg", "title": "Pakistan"},
  {"value": "pw", "img": "/assets/flags/pw.svg", "title": "Palau"},
  {"value": "ps", "img": "/assets/flags/ps.svg", "title": "Palestine, State of"},
  {"value": "pa", "img": "/assets/flags/pa.svg", "title": "Panama"},
  {"value": "pg", "img": "/assets/flags/pg.svg", "title": "Papua New Guinea"},
  {"value": "py", "img": "/assets/flags/py.svg", "title": "Paraguay"},
  {"value": "pe", "img": "/assets/flags/pe.svg", "title": "Peru"},
  {"value": "ph", "img": "/assets/flags/ph.svg", "title": "Philippines"},
  {"value": "pn", "img": "/assets/flags/pn.svg", "title": "Pitcairn"},
  {"value": "pl", "img": "/assets/flags/pl.svg", "title": "Poland"},
  {"value": "pt", "img": "/assets/flags/pt.svg", "title": "Portugal"},
  {"value": "pr", "img": "/assets/flags/pr.svg", "title": "Puerto Rico"},
  {"value": "qa", "img": "/assets/flags/qa.svg", "title": "Qatar"},
  {"value": "mk", "img": "/assets/flags/mk.svg", "title": "Republic of North Macedonia"},
  {"value": "ro", "img": "/assets/flags/ro.svg", "title": "Romania"},
  {"value": "ru", "img": "/assets/flags/ru.svg", "title": "Russian Federation"},
  {"value": "rw", "img": "/assets/flags/rw.svg", "title": "Rwanda"},
  {"value": "re", "img": "/assets/flags/re.svg", "title": "Réunion"},
  {"value": "bl", "img": "/assets/flags/bl.svg", "title": "Saint Barthélemy"},
  {"value": "sh", "img": "/assets/flags/sh.svg", "title": "Saint Helena, Ascension and Tristan da Cunha"},
  {"value": "kn", "img": "/assets/flags/kn.svg", "title": "Saint Kitts and Nevis"},
  {"value": "lc", "img": "/assets/flags/lc.svg", "title": "Saint Lucia"},
  {"value": "mf", "img": "/assets/flags/mf.svg", "title": "Saint Martin (French part)"},
  {"value": "pm", "img": "/assets/flags/pm.svg", "title": "Saint Pierre and Miquelon"},
  {"value": "vc", "img": "/assets/flags/vc.svg", "title": "Saint Vincent and the Grenadines"},
  {"value": "ws", "img": "/assets/flags/ws.svg", "title": "Samoa"},
  {"value": "sm", "img": "/assets/flags/sm.svg", "title": "San Marino"},
  {"value": "st", "img": "/assets/flags/st.svg", "title": "Sao Tome and Principe"},
  {"value": "sa", "img": "/assets/flags/sa.svg", "title": "Saudi Arabia"},
  {"value": "sn", "img": "/assets/flags/sn.svg", "title": "Senegal"},
  {"value": "rs", "img": "/assets/flags/rs.svg", "title": "Serbia"},
  {"value": "sc", "img": "/assets/flags/sc.svg", "title": "Seychelles"},
  {"value": "sl", "img": "/assets/flags/sl.svg", "title": "Sierra Leone"},
  {"value": "sg", "img": "/assets/flags/sg.svg", "title": "Singapore"},
  {"value": "sx", "img": "/assets/flags/sx.svg", "title": "Sint Maarten (Dutch part)"},
  {"value": "sk", "img": "/assets/flags/sk.svg", "title": "Slovakia"},
  {"value": "si", "img": "/assets/flags/si.svg", "title": "Slovenia"},
  {"value": "sb", "img": "/assets/flags/sb.svg", "title": "Solomon Islands"},
  {"value": "so", "img": "/assets/flags/so.svg", "title": "Somalia"},
  {"value": "za", "img": "/assets/flags/za.svg", "title": "South Africa"},
  {"value": "gs", "img": "/assets/flags/gs.svg", "title": "South Georgia and the South Sandwich Islands"},
  {"value": "ss", "img": "/assets/flags/ss.svg", "title": "South Sudan"},
  {"value": "es", "img": "/assets/flags/es.svg", "title": "Spain"},
  {"value": "lk", "img": "/assets/flags/lk.svg", "title": "Sri Lanka"},
  {"value": "sd", "img": "/assets/flags/sd.svg", "title": "Sudan"},
  {"value": "sr", "img": "/assets/flags/sr.svg", "title": "Surititle"},
  {"value": "sj", "img": "/assets/flags/sj.svg", "title": "Svalbard and Jan Mayen"},
  {"value": "se", "img": "/assets/flags/se.svg", "title": "Sweden"},
  {"value": "ch", "img": "/assets/flags/ch.svg", "title": "Switzerland"},
  {"value": "sy", "img": "/assets/flags/sy.svg", "title": "Syrian Arab Republic"},
  {"value": "tw", "img": "/assets/flags/tw.svg", "title": "Taiwan"},
  {"value": "tj", "img": "/assets/flags/tj.svg", "title": "Tajikistan"},
  {"value": "tz", "img": "/assets/flags/tz.svg", "title": "Tanzania, United Republic of"},
  {"value": "th", "img": "/assets/flags/th.svg", "title": "Thailand"},
  {"value": "tl", "img": "/assets/flags/tl.svg", "title": "Timor-Leste"},
  {"value": "tg", "img": "/assets/flags/tg.svg", "title": "Togo"},
  {"value": "tk", "img": "/assets/flags/tk.svg", "title": "Tokelau"},
  {"value": "to", "img": "/assets/flags/to.svg", "title": "Tonga"},
  {"value": "tt", "img": "/assets/flags/tt.svg", "title": "Trinidad and Tobago"},
  {"value": "tn", "img": "/assets/flags/tn.svg", "title": "Tunisia"},
  {"value": "tr", "img": "/assets/flags/tr.svg", "title": "Turkey"},
  {"value": "tm", "img": "/assets/flags/tm.svg", "title": "Turkmenistan"},
  {"value": "tc", "img": "/assets/flags/tc.svg", "title": "Turks and Caicos Islands"},
  {"value": "tv", "img": "/assets/flags/tv.svg", "title": "Tuvalu"},
  {"value": "ug", "img": "/assets/flags/ug.svg", "title": "Uganda"},
  {"value": "ua", "img": "/assets/flags/ua.svg", "title": "Ukraine"},
  {"value": "ae", "img": "/assets/flags/ae.svg", "title": "United Arab Emirates"},
  {"value": "gb", "img": "/assets/flags/gb.svg", "title": "United Kingdom"},
  {"value": "um", "img": "/assets/flags/um.svg", "title": "United States Minor Outlying Islands"},
  {"value": "us", "img": "/assets/flags/us.svg", "title": "United States of America"},
  {"value": "uy", "img": "/assets/flags/uy.svg", "title": "Uruguay"},
  {"value": "uz", "img": "/assets/flags/uz.svg", "title": "Uzbekistan"},
  {"value": "vu", "img": "/assets/flags/vu.svg", "title": "Vanuatu"},
  {"value": "ve", "img": "/assets/flags/ve.svg", "title": "Venezuela (Bolivarian Republic of)"},
  {"value": "vn", "img": "/assets/flags/vn.svg", "title": "Viet Nam"},
  {"value": "vg", "img": "/assets/flags/vg.svg", "title": "Virgin Islands (British)"},
  {"value": "vi", "img": "/assets/flags/vi.svg", "title": "Virgin Islands (U.S.)"},
  {"value": "wf", "img": "/assets/flags/wf.svg", "title": "Wallis and Futuna"},
  {"value": "eh", "img": "/assets/flags/eh.svg", "title": "Western Sahara"},
  {"value": "ye", "img": "/assets/flags/ye.svg", "title": "Yemen"},
  {"value": "zm", "img": "/assets/flags/zm.svg", "title": "Zambia"},
  {"value": "zw", "img": "/assets/flags/zw.svg", "title": "Zimbabwe"},
  {"value": "ax", "img": "/assets/flags/ax.svg", "title": "Åland Islands"}
];

