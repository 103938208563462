const state = {
  show: false
};

const getters = {
  show: state => {
    return state.show;
  }
};

const actions = {
  toggle({commit, state}) {
    commit('SET_SHOW', !state.show);
  },
  hide({commit}) {
    commit('SET_SHOW', false);
  }
};

const mutations = {
  SET_SHOW(state, show) {
    state.show = show;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
