<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Abuse Report" />
      <div class="w-full h-full max-w-md mx-auto py-5 px-5">
        <div class="flex items-center justify-between mb-5 border-b pb-3">
          <h2>Reports</h2>
          <report-abuse />
        </div>
        <template v-if="loaded">
          <div v-if="reports.length > 0">
            <report-item v-for="report in reports" :report="report" :key="`report-${report.id}`" />
          </div>
          <div class="w-full rounded-xl bg-white border border-gray-200 py-4 px-5">
            You don't have any reports
          </div>
        </template>
        <v-loader v-else class="h-3" />
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VHeader from "@/components/Header";
  import {mapState} from "vuex";
  import ReportItem from "./ReportItem.vue";
  import VLoader from "@/components/Loader";
  import ReportAbuse from "@/views/reports/ReportAbuse";

  export default {
    name: "Index",
    computed: {
      ...mapState({
        reports: (state) => state.report.reports,
      }),
    },
    components: {ReportAbuse, VHeader, AppLayout, ReportItem, VLoader},
    data() {
      return {
        loaded: false,
      };
    },
    mounted() {
      this.getReports();
    },
    methods: {
      async getReports() {
        this.loaded = false;
        await this.$store.dispatch("report/getReports");
        this.loaded = true;
      },
    },
  };
</script>
