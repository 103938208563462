import router from "@/router";

const state = {
  results: {}
};

const getters = {};

const actions = {
  async configs({dispatch}, form) {
    await window.axios.post('/search/configs', form).then(async () => {
      await dispatch('auth/fetchUser', {}, {root: true});
      await router.push({name: 'search.result'});
    }).catch(() => {
    });
  },
  async get({commit}) {
    await window.axios.get('/search/results').then((response) => {
      commit('SET_RESULTS', response.data)
    }).catch(() => {
    });
  }
};

const mutations = {
  SET_RESULTS(state, results) {
    state.results = results;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
