<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Setup your plan" />
      <div class="w-full h-full max-w-md mx-auto py-10 px-5">
        <div class="mb-3">Websites</div>
        <website :icon="require('@/assets/images/twitter.svg')" :checked="form.websites.includes('twitter')" title="Twitter" @change="websiteChanged('twitter', $event)" :disabled="!canSelectWebsite('twitter')" class="mb-3" />
        <website :icon="require('@/assets/images/reddit.svg')" :checked="form.websites.includes('reddit')" title="Reddit" @change="websiteChanged('reddit', $event)" :disabled="!canSelectWebsite('reddit')" class="mb-3" />
        <website :icon="require('@/assets/images/pornhub.svg')" :checked="form.websites.includes('pornhub')" title="Pornhub" @change="websiteChanged('pornhub', $event)" :disabled="!canSelectWebsite('pornhub')" class="mb-3" />
        <div class="text-red-500 text-sm" v-if="errors.websites">{{ errors.websites[0] }}</div>
        <div class="mt-5 mb-3">Abuse Type</div>
        <abuse-type title="Nudity" :checked="form.abuse_types.includes('nudity')" @change="abuseTypeChanged('nudity', $event)" :disabled="!canSelectAbuseType('nudity')" class="mb-3" />
        <div class="text-red-500 text-sm" v-if="errors.abuse_types">{{
            errors.abuse_types[0]
          }}
        </div>
        <div class="mt-5 mb-3">Search Frequency</div>
        <v-select2 :data="frequencies" @selected="form.frequency = $event" :value="form.frequency" :error="errors.frequency ? errors.frequency[0] : ''" />
        <div v-if="!user.has_media" class="p-3 border border-yellow-400 bg-yellow-50 rounded-xl mt-5 text-center">
          <p class="mb-2">To setup your search, You need to upload your video/photo.</p>
          <router-link :to="{name: 'upload_media'}" class="text-yellow-500 underline">
            Upload Your Video/Photo
          </router-link>
        </div>
        <v-button class="mt-5 w-full" @click="search" :disabled="processing || !user.has_media">
          Search
        </v-button>
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VHeader from "@/components/Header";
  import Website from "@/views/search/Website";
  import AbuseType from "@/views/search/AbuseType";
  import VButton from "@/components/Button";
  import VSelect2 from "@/components/Select2";
  import {mapState} from "vuex";

  export default {
    name: "Search",
    components: {VSelect2, VButton, AbuseType, Website, VHeader, AppLayout},
    computed: {
      ...mapState({
        user: state => state.auth.user,
        errors: state => state.app.errors
      }),
      canSelectWebsite() {
        return website => this.form.websites.includes(website) || this.user.plan_info.features.search_websites > this.form.websites.length
      },
      canSelectAbuseType() {
        return abuseType => this.form.abuse_types.includes(abuseType) || this.user.plan_info.features.search_abuse_types > this.form.abuse_types.length
      }
    },
    data() {
      return {
        frequencies: [
          {
            title: 'Once',
            value: 'once',
          },
          {
            title: 'Monthly',
            value: 'monthly',
          },
        ],
        processing: false,
        form: {
          websites: [],
          abuse_types: [],
          frequency: 'once'
        }
      }
    },
    mounted() {
      if (this.user.search_config) {
        this.form = this.user.search_config;
      }
    },
    methods: {
      websiteChanged(website, checked) {
        if (checked && this.canSelectWebsite(website)) {
          this.form.websites.push(website)
        } else {
          this.form.websites = this.form.websites.filter(function (value) {
            return value !== website;
          });
        }
      },
      abuseTypeChanged(abuseType, checked) {
        if (checked && this.canSelectAbuseType(abuseType)) {
          this.form.abuse_types.push(abuseType)
        } else {
          this.form.abuse_types = this.form.abuse_types.filter(function (value) {
            return value !== abuseType;
          });
        }
      },
      async search() {
        this.processing = true;
        await this.$store.dispatch('search/configs', this.form);
        this.processing = false;
      }
    }
  }
</script>
