<template>
  <transition enter-active-class="transition ease-out duration-300" enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-active-class="transition ease-in duration-200" leave-class="opacity-100 translate-y-0 sm:scale-100" leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
    <div v-show="show" class="fixed bottom-0 mx-auto m-10 cursor-pointer z-50" style="left: 50%; transform: translateX(-50%)" @click="hideToast">
      <div class="text-white py-3 px-6 w-full h-full rounded-md shadow-md text-center" :class="{'bg-green-500': type === 'success', 'bg-red-500': type === 'error'}">{{ message }}</div>
    </div>
  </transition>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: 'VToast',
    computed: {
      ...mapState({
        errors: state => state.app.errors
      })
    },
    data() {
      return {
        show: false,
        type: '',
        message: '',
        timeout: null
      };
    },
    watch: {
      errors(newVal) {
        if (newVal.message && newVal.message.length > 0) {
          this.showToast('error', newVal.message[0]);
        }
      }
    },
    methods: {
      showToast(type, message) {
        this.hideToast();
        this.type = type;
        this.message = message;
        this.show = true;
        this.timeout = setTimeout(function () {
          this.show = false;
        }.bind(this), 5000);
      },
      hideToast() {
        this.show = false;
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      },
      singleValidation(response) {
        if (response.data.errors && Object.values(response.data.errors).length > 0 && Object.values(response.data.errors)[0].length > 0) {
          this.showToast('error', Object.values(response.data.errors)[0][0]);
        }
      }
    }
  }
</script>
