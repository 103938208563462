<template>
  <div class="relative border border-gray-200 rounded-xl flex items-center justify-between px-4 py-5 cursor-pointer" :class="{'opacity-50 filter grayscale': disabled}">
    <div @click="$refs.checkbox.triggerClick()" class="absolute w-full h-full z-10"></div>
    <div class="flex items-center">
      <v-checkbox ref="checkbox" class="mr-4" @change="changed($event)" :disabled="disabled" :checked="checked" />
      <span class="text-lg">{{ title }}</span>
    </div>
    <img :src="icon" class="h-7" alt="Icon">
  </div>
</template>

<script>
  import VCheckbox from "@/components/Checkbox";

  export default {
    name: "Website",
    components: {VCheckbox},
    props: {
      icon: String,
      title: String,
      disabled: Boolean,
      checked: Boolean
    },
    methods: {
      changed(checked) {
        !this.disabled && this.$emit('change', checked)
      }
    }
  }
</script>
