<template>
  <app-layout>
    <div class="w-full min-h-screen-cover">
      <div class="max-w-md mx-auto p-10 min-h-screen-cover flex flex-col justify-between items-start">
        <div class="w-full flex items-center justify-center">
        </div>
        <div class="relative z-10">
          <img class="h-28 mx-auto mb-10" :src="require('@/assets/images/logo.svg')" alt="YFB">

          <h2 class="text-primary-500 text-2xl font-bold text-center mb-4">
            You are all set!
          </h2>
          <p class="text-center text-primary-500 font-normal">
            Your account is successfully created! You can start checking and monitoring Your Face
            Brand
          </p>
        </div>
        <div class="w-full">
          <v-button class="w-full" @click="$router.replace({name: 'search'})">Continue</v-button>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VButton from "@/components/Button";

  export default {
    name: "UploadMediaDone",
    components: {VButton, AppLayout}
  }
</script>
