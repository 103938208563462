<template>
  <div class="w-full bg-primary-500">
    <div class="max-w-md mx-auto py-3 px-5 flex items-center justify-between">
      <div class="w-3/12 flex items-center justify-start">
        <img v-if="back" @click="goBack" :src="require('@/assets/icons/back-arrow.svg')" alt="Back" class="w-8 h-8 cursor-pointer">
        <img v-else :src="require('@/assets/images/logo-white.svg')" alt="Logo" class="h-8">
      </div>
      <div class="w-5/12 flex items-center justify-center">
        <div class="text-xl text-white text-center">{{ title }}</div>
      </div>
      <div class="w-3/12 flex items-center justify-end">
        <img v-if="sidebar" @click="toggleSidebar" :src="require('@/assets/icons/sidebar-toggle.svg')" alt="Toggle" class="w-8 h-8 cursor-pointer">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "VHeader",
    props: {
      title: String,
      sidebar: {
        type: Boolean,
        default: true
      },
      back: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toggleSidebar() {
        this.$store.dispatch('sidebar/toggle');
      },
      goBack() {
        this.$router.back();
      }
    }
  }
</script>
