<template>
  <div class="flex items-center justify-center w-full">
    <a href="#" class="mx-2">
      <img :src="require('@/assets/images/facebook.svg')" alt="Facebook" class="h-7">
    </a>
    <a href="#" class="mx-2">
      <img :src="require('@/assets/images/twitter.svg')" alt="Twitter" class="h-7">
    </a>
    <a href="#" class="mx-2">
      <img :src="require('@/assets/images/slack-icon.svg')" alt="Slack" class="h-7">
    </a>
    <a href="#" class="mx-2">
      <img :src="require('@/assets/images/instagram.svg')" alt="Instagram" class="h-7">
    </a>
  </div>
</template>

<script>
  export default {
    name: "SocialIcons"
  }
</script>

<style scoped>

</style>
