<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Search results" />
      <div class="w-full h-full max-w-md mx-auto py-5 px-5">
        <template v-if="loaded">
          <div v-if="results.data.length > 0">
            <result-item v-for="(result) in results.data" :result="result" :key="`search-result-${result.id}`" />
          </div>
          <div class="w-full rounded-xl bg-white border border-gray-200 py-4 px-5">
            No results found
          </div>
        </template>
        <v-loader v-else class="h-3" />
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VHeader from "@/components/Header";
  import {mapState} from "vuex";
  import VLoader from "@/components/Loader";
  import ResultItem from "@/views/search/ResultItem";

  export default {
    name: "Result",
    components: {ResultItem, VLoader, VHeader, AppLayout},
    computed: {
      ...mapState({
        user: state => state.auth.user,
        results: state => state.search.results
      })
    },
    data() {
      return {
        loaded: false
      }
    },
    mounted() {
      this.getResults();
    },
    methods: {
      async getResults() {
        await this.$store.dispatch('search/get');
        this.loaded = true;
      },
    }
  }
</script>
