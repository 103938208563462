<template>
  <div class="w-full md:min-h-screen">
    <slot></slot>
    <sidebar v-if="sidebar" />
  </div>
</template>

<script>
  import Sidebar from "@/components/Sidebar";
  import {mapState} from "vuex";

  export default {
    name: "AppLayout",
    components: {Sidebar},
    computed: {
      ...mapState({
        sidebar: state => state.sidebar.show,
      })
    },
  };
</script>
