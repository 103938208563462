<template>
  <div class="inline-flex flex-col">
    <div class="relative inline-flex items-center rounded-lg justify-between border border-gray-300 py-2 px-3" :class="{'border-ice': focused && !error, 'border-red-500': error}">
      <label v-if="innerValue.length > 0 || focused" :for="id" class="bg-white absolute left-0 -top-2 ml-10 text-xs px-2" :class="{'text-ice': focused && !error, 'text-gray-500': !focused && !error, 'text-red-500': error}">
        {{ label }}
      </label>
      <label v-else :for="id" class="absolute ml-7 cursor-text text-gray-500" @click="$refs.input.focus()">
        {{ label }}
      </label>
      <div v-if="$slots.icon" class="flex items-center justify-center fill-current w-5 h-5 mr-2" :class="{'text-ice': focused, 'text-gray-500': !focused}">
        <slot name="icon"></slot>
      </div>
      <input ref="input" :id="id" :type="type" class="focus:outline-none w-full disabled:bg-white disabled:text-gray-600" v-model="innerValue" @input="$emit('input', $event.target.value)" @focus="focused = true" @blur="focused = false" :placeholder="placeholder" v-bind="$attrs">
    </div>
    <small v-if="error" class="text-xs text-red-500 mt-1 ml-1">{{ error }}</small>
  </div>
</template>

<script>
  export default {
    name: "VInput",
    props: {
      id: String,
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String
      },
      value: null,
      icon: String,
      label: String,
      error: String
    },
    mounted() {
      if (this.value) {
        this.innerValue = this.value;
      }
    },
    data() {
      return {
        focused: false,
        innerValue: ''
      }
    },
    watch: {
      innerValue(newVal) {
        this.$emit('input', newVal);
      },
      value(newVal) {
        this.innerValue = newVal;
      }
    }
  }
</script>
