<template>
  <app-layout>
    <div class="w-full min-h-screen-cover bg-gradient-to-b from-white to-ice-500">
      <template v-if="recorder">
        <div class="max-w-md mx-auto p-10 min-h-screen-cover">
          <div v-if="recording" class="fixed bottom-0 mb-10 mx-auto text-center px-5 py-2 rounded-xl text-white mb-3 text-lg bg-red-600" style="left: 50%; transform: translateX(-50%)">
            Recording...
          </div>
          <div id="recorder-container" :class="{'hidden': playing, 'block': !playing}">
            <video id="video" class="w-full h-56 border rounded-lg shadow-lg mb-10" autoplay></video>
          </div>
          <div id="player-container" :class="{'hidden': !playing, 'block': playing}">
          </div>
          <div>
            <v-button class="w-full mb-3" @click="stopRecording" v-if="recording">
              Stop Recording
            </v-button>
            <v-button class="w-full mb-3" @click="startRecording" v-else>
              Start Recording
            </v-button>
            <v-button class="w-full mb-3" v-if="recordingFinished" @click="playRecorded">
              Play it Again
            </v-button>
            <v-button class="w-full mb-3" v-if="recordingFinished" @click="upload" :disabled="uploading">
              Upload This Video
            </v-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="max-w-md mx-auto p-10 min-h-screen-cover flex flex-col justify-between items-start">
          <div class="w-full flex items-center justify-center">
          </div>
          <div class="relative z-10">
            <img class="h-28 mx-auto mb-10" :src="require('@/assets/images/upload-video.svg')" alt="YFB">

            <h2 class="text-primary-500 text-2xl font-bold text-center mb-4">
              For your own security, we would like to verify that this is really you!
            </h2>
            <p class="text-center text-primary-500 font-normal">
              Please take a video/photo of your face making sure your characteristics are quite visible. For best results you can move your face slowly up, down, left and right and stop when you are facing right at the camera. Upload your video when you are ready!
            </p>
          </div>
          <div class="w-full">
            <v-button class="w-full mb-3 hidden md:block" @click="showRecorder">Upload Video
            </v-button>
            <div class="w-full relative md:hidden mb-3">
              <v-button class="w-full" :disabled="uploading">
                Upload Video
              </v-button>
              <input ref="video" type="file" class="opacity-0 absolute z-50 w-full h-full left-0 right-0" @change="fileSelected" accept="video/*" capture="user">
            </div>
            <div class="w-full relative mb-3">
              <v-button class="w-full" :disabled="uploading" @click="showSelectPhoto">
                Upload Photo
              </v-button>
              <input ref="photo" type="file" class="hidden" @change="photoFileSelected" accept="image/*" capture="user">
            </div>
            <v-button class="w-full" @click="doItLater">I will do it later</v-button>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  import VButton from "@/components/Button";

  export default {
    name: 'UploadMedia',
    components: {VButton, AppLayout},
    beforeDestroy() {
      if (this.stream) {
        this.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    },
    data() {
      return {
        recorder: false,
        stream: null,
        streamTrack: null,
        mediaRecorder: null,
        records: [],
        recordedVideo: null,
        recordedVideoBlob: null,
        recording: false,
        playing: false,
        recordingFinished: false,
        uploading: false
      }
    },
    methods: {
      async showRecorder() {
        this.stream = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
        this.recorder = true;
        setTimeout(() => {
          document.getElementById('video').srcObject = this.stream;
        }, 100)
        this.streamTrack = this.stream.getTracks()[0];
      },
      async startRecording() {
        await this.showRecorder();
        this.recordingFinished = false;
        this.playing = false;
        this.records = [];
        this.recordedVideo = null;
        this.mediaRecorder = new MediaRecorder(this.stream, {mimeType: 'video/webm'});
        this.mediaRecorder.addEventListener('dataavailable', (e) => {
          this.records.push(e.data);
        });
        await this.mediaRecorder.start(1000);
        this.recording = true;
      },
      async stopRecording() {
        this.recordedVideoBlob = new Blob(this.records, {type: 'video/webm'});
        this.recordedVideo = URL.createObjectURL(this.recordedVideoBlob);
        await this.mediaRecorder.stop();
        this.mediaRecorder = null;
        this.recording = false;
        this.recordingFinished = true;
        this.playRecorded().then();
        this.streamTrack.stop();
      },
      async playRecorded() {
        this.playing = true;
        let videoContainer = document.getElementById('player-container');
        videoContainer.innerHTML = "";
        let video = document.createElement('video');
        video.classList = "w-full h-56 border rounded-lg shadow-lg mb-10";
        video.setAttribute('id', 'video-player');
        let source = document.createElement('source');
        source.src = this.recordedVideo;
        source.type = 'video/mp4';
        video.appendChild(source);
        videoContainer.appendChild(video);
        await video.play();
      },
      async upload() {
        this.uploading = true;
        let form = new FormData();
        form.append('type', 'video');
        form.append('video', this.recordedVideoBlob);
        await this.$store.dispatch('media/upload', form);
        this.uploading = false;
      },
      doItLater() {
        this.$router.push({name: 'search'});
      },
      selectFile() {
        this.$refs.video.click();
      },
      async fileSelected(event) {
        this.uploading = true;
        let form = new FormData();
        form.append('type', 'video');
        form.append('video', event.target.files[0]);
        await this.$store.dispatch('media/upload', form);
        this.uploading = false;
      },
      showSelectPhoto() {
        this.$refs.photo.click();
      },
      async photoFileSelected(event) {
        this.uploading = true;
        let form = new FormData();
        form.append('type', 'photo');
        form.append('photo', event.target.files[0]);
        await this.$store.dispatch('media/upload', form);
        this.uploading = false;
      }
    }
  }
</script>
