import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';

import SplashIndex from '@/views/splash/Index';
import AuthIndex from "@/views/auth/Index";
import AuthInfo from "@/views/auth/Info";
import AuthGoogle from "@/views/auth/Google";
import Plans from '@/views/plans/Index';
import UploadMedia from "@/views/medias/Upload";
import UploadMediaDone from "@/views/medias/UploadDone";
import Search from "@/views/search/Index";
import Result from "@/views/search/Result";
import Reports from "@/views/reports/Index";
import ShowReport from "@/views/reports/Show";
import Profile from "@/views/profile/Profile";
import Medias from "@/views/medias/Medias";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'splash',
      component: SplashIndex,
      meta: {
        redirect_if_authenticated: true
      }
    },
    {
      path: '/auth',
      name: 'auth',
      component: AuthIndex,
      meta: {
        redirect_if_authenticated: true
      }
    },
    {
      path: '/auth/google',
      name: 'auth.google',
      component: AuthGoogle,
      meta: {
        redirect_if_authenticated: true
      }
    },
    {
      path: '/auth/info',
      name: 'auth.info',
      component: AuthInfo,
      meta: {
        auth: true,
        registration_completed: false,
        registration_step: 'info'
      }
    },
    {
      path: '/plans',
      name: 'plans',
      component: Plans,
      meta: {
        auth: true
      }
    },
    {
      path: '/upload-media',
      name: 'upload_media',
      component: UploadMedia,
      meta: {
        auth: true
      }
    },
    {
      path: '/upload-media/done',
      name: 'upload_media.done',
      component: UploadMediaDone,
      meta: {
        auth: true,
      }
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: {
        auth: true
      }
    },
    {
      path: '/search/result',
      name: 'search.result',
      component: Result,
      meta: {
        auth: true,
        registration_completed: true
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      meta: {
        auth: true,
        registration_completed: true
      }
    },
    {
      path: '/reports/:id',
      name: 'reports.show',
      component: ShowReport,
      meta: {
        auth: true,
        registration_completed: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        auth: true,
        registration_completed: true
      }
    },
    {
      path: '/medias',
      name: 'medias',
      component: Medias,
      meta: {
        auth: true,
        registration_completed: true
      }
    },
  ],
  mode: 'history'
});

// auth middleware
router.beforeEach(async (to, from, next) => {
  await store.dispatch('sidebar/hide');
  if (to.meta.auth && to.name !== 'auth' && !store.state.auth.authenticated) {
    next({
      name: 'auth',
      params: {redirectTo: to.fullPath}
    });
  } else {
    next();
  }
});

// redirect if authenticated
router.beforeEach((to, from, next) => {
  if (to.meta.redirect_if_authenticated && store.state.auth.authenticated) {
    next({
      name: store.state.app.home_route,
      params: {redirectTo: to.fullPath}
    });
  } else {
    next();
  }
});

// registration step middleware
router.beforeEach((to, from, next) => {
  if (to.meta.registration_step) {
    if (to.meta.registration_step === store.state.auth.user.registration_step) {
      next();
    } else {
      router.replace({name: store.state.app.home_route});
    }
  } else {
    next();
  }
});

export default router;
