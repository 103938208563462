<template>
  <div class="relative border border-gray-200 rounded-xl flex items-center justify-start px-4 py-5 cursor-pointer">
    <div @click="$refs.checkbox.triggerClick()" class="absolute w-full h-full z-10"></div>
    <v-checkbox ref="checkbox" class="mr-4" @change="changed($event)" :disabled="disabled" :checked="checked" />
    <span class="text-lg">{{ title }}</span>
  </div>
</template>

<script>
  import VCheckbox from "@/components/Checkbox";

  export default {
    name: "AbuseType",
    components: {VCheckbox},
    props: {
      icon: String,
      title: String,
      disabled: Boolean,
      checked: Boolean
    },
    methods: {
      changed(checked) {
        !this.disabled && this.$emit('change', checked)
      }
    }
  }
</script>
