import Vue from 'vue';
import Vuex from 'vuex';

import App from './modules/app.store';
import Config from './modules/config.store';
import Auth from './modules/auth.store';
import Sidebar from './modules/sidebar.store';
import Subscription from './modules/subscription.store';
import Media from './modules/media.store';
import Search from './modules/search.store';
import Profile from './modules/profile.store';
import Report from './modules/report.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: App,
    config: Config,
    auth: Auth,
    sidebar: Sidebar,
    subscription: Subscription,
    media: Media,
    search: Search,
    profile: Profile,
    report: Report
  }
});
