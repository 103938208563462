import Vue from 'vue'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

Vue.use(VuePersianDatetimePicker, {
  name: 'date-picker',
  props: {
    locale: 'en',
    format: 'DD/MM/YYYY',
    color: '#0FBAC7',
  }
});
