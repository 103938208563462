const state = {
  updated: false
};

const getters = {};

const actions = {
  async update({commit}, form) {
    await window.axios.put('/profile', form).then(async () => {
      commit('app/SET_ERRORS', [], {root: true});
      commit('SET_UPDATED', true);
    }).catch(() => {
    });
  },
};

const mutations = {
  SET_UPDATED(state) {
    state.updated = true;
    setTimeout(() => {
      state.updated = false;
    }, 2000)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
