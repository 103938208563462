<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Complete Info" :sidebar="false" />
      <div class="w-full h-full max-w-md mx-auto py-10 px-5">
        <div class="text-center mb-5 text-gray-600 px-5">
          Almost there, just a couple of final information.
        </div>
        <v-input class="w-full mb-5" :value="user.email" label="Email" :disabled="true">
          <template #icon>
            <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" />
            </svg>
          </template>
        </v-input>
        <v-input class="w-full mb-5" v-model="form.first_name" label="First Name" :error="errors.first_name ? errors.first_name[0] : ''">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" />
            </svg>
          </template>
        </v-input>
        <v-input class="w-full mb-5" v-model="form.last_name" label="Last Name" :error="errors.last_name ? errors.last_name[0] : ''">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" />
            </svg>
          </template>
        </v-input>
        <date-picker @input="form.birth_date=$event" element="birthdate" auto-submit></date-picker>
        <v-input type="tel" class="w-full mb-5" v-model="form.birth_date" :value="form.birth_date" :error="errors.birth_date ? errors.birth_date[0] : ''" label="Date of birth (dd/mm/yyyy)" v-mask="'##/##/####'">
          <template #icon>
            <svg id="birthdate" class="cursor-pointer fill-current hover:text-ice" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z" />
            </svg>
          </template>
        </v-input>
        <v-select2 class="w-full mb-5" label="Country" :value="form.country" :data="countries" @selected="form.country = $event" :error="errors.country ? errors.country[0] : ''" :filters="true">
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" />
            </svg>
          </template>
        </v-select2>
        <div class="inline-flex items-center mb-3">
          <v-checkbox id="privacy-checkbox" class="mr-2" v-model="form.tos" :error="errors.tos ? errors.tos[0] : ''"></v-checkbox>
          <label for="privacy-checkbox" class="text-2xs text-gray-400">
            By proceeding to register and using YFB App, I indicate that I read and agree to the
            Terms of Service, Privacy Policy and Cookie Policy
          </label>
        </div>
        <div class="inline-flex items-center mb-5">
          <v-checkbox id="updates-checkbox" class="mr-2" v-model="form.receive_updates" :error="errors.receive_updates ? errors.receive_updates[0] : ''"></v-checkbox>
          <label for="updates-checkbox" class="text-2xs text-gray-400">
            Communication Preferences: By checking this box, I indicate that I would like to receive
            updates about YFB products and services, promotions, special offers, news and/or events
          </label>
        </div>
        <v-button type="secondary" class="w-full mb-5" @click="register" :disabled="processing">
          Register
        </v-button>
      </div>
    </div>
  </app-layout>
</template>

<script>
  import {mapState} from "vuex";
  import AppLayout from "@/layouts/AppLayout";
  import VInput from "@/components/Input";
  import VButton from "@/components/Button";
  import VHeader from "@/components/Header";
  import VCheckbox from "@/components/Checkbox";
  import VSelect2 from "@/components/Select2";
  import countries from "@/helpers/countries";

  export default {
    name: "Info",
    components: {
      VSelect2,
      VCheckbox,
      VHeader,
      VButton,
      VInput,
      AppLayout
    },
    computed: {
      ...mapState({
        user: state => state.auth.user,
        errors: state => state.app.errors
      })
    },
    data() {
      return {
        form: {
          first_name: '',
          last_name: '',
          birth_date: '',
          country: '',
          tos: false,
          receive_updates: false
        },
        countries: countries,
        processing: false
      }
    },
    mounted() {
      this.form.first_name = this.user.first_name;
      this.form.last_name = this.user.last_name;
      this.form.birth_date = this.user.birth_date;
      this.form.country = this.user.country;
    },
    methods: {
      async register() {
        this.processing = true;
        await this.$store.dispatch('auth/completeInfo', this.form);
        this.processing = false;
      }
    }
  }
</script>
