const state = {
  api_url: process.env.VUE_APP_API_URL,
};

const getters = {
  api_url: state => {
    return state.api_url;
  }
};

export default {
  namespaced: true,
  state,
  getters
};
