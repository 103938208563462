import router from "@/router";

const state = {
  medias: []
};

const getters = {};

const actions = {
  async upload({dispatch}, data) {
    await window.axios.post('/medias', data).then(async () => {
      await dispatch('auth/fetchUser', {}, {root: true});
      await router.push({name: 'upload_media.done'});
    }).catch(() => {
    });
  },
  async get({commit}) {
    await window.axios.get('/medias').then((response) => {
      commit('SET_MEDIAS', response.data)
    }).catch(() => {
    });
  }
};

const mutations = {
  SET_MEDIAS(state, medias) {
    state.medias = medias;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
