<template>
  <app-layout>
    <div class="h-full flex flex-col justify-between items-start">
      <v-header title="Connect" :sidebar="false" />
      <div class="w-full h-full max-w-md mx-auto py-10 px-5">
        <v-button type="secondary" class="w-full mb-5" @click="connectWithGoogle">Connect with
          Google
        </v-button>
        <social-icons class="mb-10" />
      </div>
    </div>
  </app-layout>
</template>

<script>
  import AppLayout from "@/layouts/AppLayout";
  // import VInput from "@/components/Input";
  // import IconEmail from "@/components/icons/Email";
  // import IconPhone from "@/components/icons/Phone";
  import SocialIcons from "@/views/auth/SocialIcons";
  import VButton from "@/components/Button";
  import VHeader from "@/components/Header";

  export default {
    name: "Auth",
    components: {
      VHeader,
      VButton,
      SocialIcons,
      // IconPhone,
      // IconEmail,
      // VInput,
      AppLayout
    },
    methods: {
      register() {
        //
      },
      connectWithGoogle() {
        this.$store.dispatch('auth/google');
      }
    }
  }
</script>
