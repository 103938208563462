import Vue from 'vue';
import App from './App.vue';

require('./plugins');
require('./components');
require('./filters/dateformat');

import "./assets/css/app.css";

import router from './router';
import store from './store'
import {mapState} from "vuex";

Vue.config.productionTip = false;

async function init() {
  await store.dispatch('auth/fetchUser');
  new Vue({
    router,
    store,
    render: h => h(App),
    computed: {
      ...mapState({
        authenticated: state => state.auth.authenticated,
        user: state => state.auth.user,
      })
    },
    async created() {
      window.axios.interceptors.response.use(null, async (error) => {
        if (error.response.status === 401 && this.$route.name !== 'auth.login') {
          await this.$router.replace({name: 'auth.login'});
          return;
        }
        if (error.response.status === 404) {
          this.$store.commit('app/SET_ERRORS', [error.response.data.message]);
        }
        if (error.response.status === 422) {
          this.$store.commit('app/SET_ERRORS', error.response.data.errors);
        }
        return Promise.reject(error);
      });
      this.$store.commit('app/SET_READY', true);
      if (this.authenticated && this.user) {
        await this.$store.dispatch('auth/redirect');
      }
    },
    watch: {
      $route() {
        this.$store.commit('app/SET_ERRORS', {});
      }
    }
  }).$mount('#app');
}

init().then();
