<template>
  <div v-click-outside="closeDropdown">
    <div class="relative">
      <span class="inline-block w-full rounded-lg shadow-sm">
        <button
          type="button" @click="openDropdown" aria-haspopup="listbox" aria-expanded="true"
          aria-labelledby="listbox-label"
          :class="{'border-red-500': error}"
          class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pr-10 py-2 focus:outline-none focus:border-ice transition ease-in-out duration-150"
        >
          <div class="flex items-center space-x-3 h-6">
            <label v-if="selected.value || isOpen" class="bg-white absolute left-0 -top-2 ml-10 text-xs px-2" :class="{'text-ice': isOpen && !error, 'text-gray-500': !isOpen && !error, 'text-red-500': error}">
              {{ label }}
            </label>
            <label v-else class="absolute ml-10 cursor-pointer text-gray-500">
              {{ label }}
            </label>
            <div v-if="$slots.icon" class="flex items-center justify-center fill-current w-5 h-5 mr-2" :class="{'text-ice': isOpen && !error, 'text-gray-500': !isOpen && !error, 'text-red-500': error}">
              <slot name="icon"></slot>
            </div>
            <template v-if="selected">
              <div
                v-if="selected.img"
                class="flex-shrink-0 h-5 w-5 bg-contain bg-no-repeat bg-center rounded-full"
                :style="'background-image: url(' + selected.img + ');'"
              ></div>
              <span class="block truncate"> {{ selected.title }}</span>
            </template>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
              stroke="currentColor"
            > <path
              d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round"
            /> </svg>
          </span>
        </button>
      </span>
      <transition
        enter-active-class="slide-down duration-100" enter-class="opacity-0"
        enter-to-class="opacity-100" leave-active-class="ease-in duration-100"
        leave-class="opacity-100" leave-to-class="opacity-0"
      >
        <div
          v-show="isOpen" class="absolute mt-1 w-full rounded-md bg-white border border-gray-300 shadow-xl"
          style="z-index: 4"
        >
          <input v-if="filters" type="text" v-model="filter" class="w-full border-b border-gray-300 mb-2 px-4 py-1 rounded-t-lg" placeholder="Filter" autocomplete="false" />
          <ul
            tabindex="-1" role="listbox" aria-labelledby="listbox-label"
            class="rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 overflow-y-auto"
            style="max-height: 300px;"
          >
            <li
              tabindex="0" @click="select(d)" id="listbox-item-0" role="option"
              v-for="(d, index) in filteredData" v-bind:key="index"
              class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9  cursor-pointer hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:text-gray-800 focus:bg-gray-200"
              :class="{'bg-gray-100': isSelected(d)}"
            >
              <div class="flex items-center space-x-3">
                <div
                  v-if="d.img"
                  class="flex-shrink-0 h-5 w-5 bg-contain bg-no-repeat bg-center rounded-full"
                  :style="'background-image: url(' + d.img + ');'"
                ></div>
                <span
                  class="block truncate"
                  v-bind:class="{'font-normal': !isSelected(d), 'font-semibold': isSelected(d), 'text-gray-500': d.disabled}"
                > {{ d.title }}
                </span>
              </div>
              <span
                v-show="isSelected(d)"
                class="absolute inset-y-0 right-0 flex items-center pr-4"
              >
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"> <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                /> </svg>
              </span>
            </li>
            <li
              v-if="filteredData.length === 0"
              tabindex="0" role="option"
              class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 cursor-default"
            >
              No result
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <p v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</p>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside'

  export default {
    name: 'VSelect2',
    props: ['data', 'value', 'label', 'error', 'filters'],
    data() {
      return {
        isOpen: false,
        selected: {},
        focused: false,
        filter: '',
        filteredData: this.data
      }
    },
    mounted() {
      this.setSelected();
    },
    watch: {
      value() {
        this.setSelected();
      },
      filter(newVal) {
        if (newVal === '') {
          this.filteredData = this.data;
        } else {
          this.filteredData = this.data.filter(item => item.title.toLowerCase().includes(newVal.toLowerCase()));
        }
      }
    },
    methods: {
      isSelected(item) {
        return this.value === item.value;
      },
      closeDropdown() {
        this.isOpen = false;
      },
      openDropdown() {
        this.isOpen = !this.isOpen;
      },
      select(item) {
        if (!item.disabled) {
          this.isOpen = false;

          this.$emit('selected', item.value);
        }
      },
      setSelected() {
        this.data.map((item) => {
          if (item.value.toString() === this.value.toString()) {
            this.selected = item;
            this.select(item);
          }
        });
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>
