const state = {};

const getters = {};

const actions = {
  async selectPlan({commit}, plan) {
    await window.axios.put('/user/plan', {plan: plan}).then(response => {
      commit('auth/SET_USER', response.data, {root: true});
    }).catch(() => {
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
