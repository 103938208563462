<template>
  <app-layout>
    <div class="h-full flex items-center justify-center">
      Authenticating...
    </div>
  </app-layout>
</template>
<script>
  import AppLayout from "@/layouts/AppLayout";
  import {mapState} from "vuex";

  export default {
    name: "Google",
    components: {AppLayout},
    computed: {
      ...mapState({
        home_route: state => state.app.home_route,
        user: state => state.auth.user
      })
    },
    async created() {
      await this.$store.dispatch('auth/googleCallback', this.$route.query.token);
      if (this.user.registration_completed) {
        this.$router.replace({name: this.home_route});
      } else {
        await this.$store.dispatch('auth/redirect');
      }
    }
  }
</script>
