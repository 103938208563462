<template>
  <div v-if="!cookie" class="fixed bottom-0 left-0 right-0 z-50 bg-ice">
    <div class="w-full max-w-md mx-auto py-3 px-5 flex items-center justify-between">
      <div class="text-white">This website uses cookies</div>
      <v-button @click="accept">Accept</v-button>
    </div>
  </div>
</template>

<script>
  import VButton from "@/components/Button";

  export default {
    name: "Cookie",
    components: {VButton},
    data() {
      return {
        cookie: false
      }
    },
    created() {
      let cookie = localStorage.getItem('cookie');
      if (cookie === '1') {
        this.cookie = true;
      }
    },
    methods: {
      accept() {
        this.cookie = true;
        localStorage.setItem('cookie', '1');
      }
    }
  }
</script>
